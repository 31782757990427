export const sendGaEvent = (event: any) => {
    try {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push(event);
    } catch(e) {}
}

export const getSendGaEventFunction = (event: any) => {
    return () => {sendGaEvent(event)}
}

export const appendCanddiScript = () => {
    if (typeof window !== 'undefined') {
        try {
            const el = document.createElement('script');
            el.async = true;
            el.type = 'text/javascript';
            el.src = "//cdns.canddi.com/p/278006fa6e2d1d6f623117924b5a9d5c.js";
            document.body.appendChild(el);
        } catch (e) {
        }
    }
}

export const appendCanddiScriptOnRule = (number: number) => {
    if (number > 0) {
        appendCanddiScript();
    }
}