import {ProductValueType} from "../SelectProduct"
import {ProductProps, SelectOptionType} from "src/components/organisms/DownloadUniversal/utils"

export type BundleType = "jdk" | "jdk-full" | "jdk-lite" | "jre" | "jre-full" | "jdk-crac" | "jre-crac"

export const BundleOrder = [
    "jdk",
    "jre",
    "jdk-lite",
    "jdk-full",
    "jre-full",
    "jdk-crac",
    "jre-crac",
    "core",
    "full",
    "standard",
]
const BundleJDKMap = {
    jdk: "Standard JDK",
    jre: "Standard JRE",
    "jdk-lite": "Lite JDK",
    "jdk-full": "Full JDK",
    "jre-full": "Full JRE",
    "jdk-crac": "CRaC JDK",
    "jre-crac": "CRaC JRE",
}
export const BundleJDKOrder: BundleJDKKey[] = [
    "jdk",
    "jre",
    "jdk-lite",
    "jdk-full",
    "jre-full",
    "jdk-crac",
    "jre-crac",
]

const BundleNIKMap = {
    standard: "Standard",
    core: "Core",
    full: "Full",
    js: "JS",
    nodejs: "NodeJS",
    llvm: "LLVM",
    wasm: "WASM",
    python: "Python",
    ruby: "Ruby",
    "js-java": "JS Java",
    "nodejs-java": "NodeJS Java",
    "llvm-java": "LLVM Java",
    "wasm-java": "WASM Java",
    "python-java": "Python Java",
    "ruby-java": "Ruby Java",
}
export const BundleNIKOrder: BundleNIKKey[] = ["core", "standard", "full"]

const OSOreder: OSKey[] = ["linux", "linux-musl", "solaris", "macos", "windows"]
const OSMap = {
    linux: "Linux",
    "linux-musl": "Alpine Linux",
    macos: "macOS",
    solaris: "Solaris",
    windows: "Windows",
}

export const getBundleLabel = (key: string): string => {
    return BundleJDKMap[key as BundleJDKKey]
        ? BundleJDKMap[key as BundleJDKKey]
        : BundleNIKMap[key as BundleNIKKey] ? BundleNIKMap[key as BundleNIKKey] : key
}

export type BundleJDKKey = keyof typeof BundleJDKMap
export type BundleNIKKey = keyof typeof BundleNIKMap
export type OSKey = keyof typeof OSMap

const arcMap: Record<string, string> = {
    x86: "x86",
    arm: "ARM",
    ppc: "PPC",
    sparc: "SPARC",
    riscv: "RISC-V",
}
export const getArchLabel = (key: string): string => {
    return arcMap[key]
}

export const getOSSelectOptions = (list: OSKey[]): SelectOptionType[] => {
    const sorted: OSKey[] = []
    const set = new Set(list)
    OSOreder.forEach(key => {
        set.has(key) && sorted.push(key)
    })
    return sorted.map(key => {
        return {label: getOSLabel(key), value: key}
    })
}

export const getPackageSelectOptions = <T>(
    list: T[],
    product: ProductValueType
): SelectOptionType[] => {
    const sorted: string[] = []
    const set = new Set(list)
    let order: any[] = null

    if (product === "LNIK") {
        order = BundleNIKOrder
    }
    if (product === "LJDK") {
        order = BundleJDKOrder
    }
    if (order) {
        order.forEach(key => {
            set.has(key) && sorted.push(key)
        })
    } else {
        for (const item of set) {
            sorted.push(item)
        }
    }


    return sorted.map(key => {
        return {label: getBundleLabel(key), value: key}
    })
}

export const getReleaseVersionSelectOptions = (
    data: any
): SelectOptionType[] => {
    return data.map((el: any) => {
        return {label: el.version, value: el.version}
    })
}

export const getJDKSourceDownlowdURL = (
    releaseVersion: string,
    srcMap: any
) => {
    const trimmedVersion = trimPlusInVersion(releaseVersion)
    let url = ""
    if (srcMap[trimmedVersion]) {
        url = `https://download.bell-sw.com/java/${releaseVersion}/bellsoft-jdk${releaseVersion}-src-full.tar.gz`
    }

    return url
}

export const getNIKSourceDownlowdURL = (
    releaseVersion: string,
    javaVersion: string,
    srcMap: any
) => {
    const url = srcMap[`${releaseVersion}_${javaVersion}`]?.downloadUrl
    return url
}

export function createSrcMap(items: ProductProps[]) {
    const srcMap: Record<string, ProductProps> = {}
    items.forEach(item => {
        if (item.packageType === "src.tar.gz") {
            // TODO There is a custom logic how to provide download url link for not LNIK
            if (item.bundleType === "jdk-full") {
                const key = trimPlusInVersion(item.version)
                srcMap[key] = item
            }
        }
    })
    return srcMap
}

export function createAdvancedSrcMap(items: ProductProps[]) {
    const srcMap: any = {}
    items.forEach(item => {
        if (item.packageType === "src.tar.gz") {
            const key = item.version
            if (!srcMap[key]) srcMap[key] = {}
            srcMap[key][item.bundleType] = item
        }
    })
    return srcMap
}

export const trimPlusInVersion = (version: string) => {
    const trimmedVersion = version.match(/[^+]+/)
    const cutJava = trimmedVersion !== null ? trimmedVersion[0] : version
    return cutJava
}

export const getOSLabel = (os: OSKey) => {
    return OSMap[os] ? OSMap[os] : os
}

export const isStringNotEmpty = (str: string): boolean => {
    let result = false;
    if (str && str !== "") {
        result = true;
    }
    return result;
}

export const setFlag = (name: string) => {
    localStorage.setItem(name, "true")
    if (typeof window !== "undefined") {
        try {
            const event = new Event(name);
            window.document.dispatchEvent(event)
        } catch (e) {

        }
    }
}

export const getFlag = (name: string) => {
    try {
        return localStorage.getItem(name)
    }
    catch (e) {
        return null
    }
}

export const incrementCounterFlag = (name: string) => {
    try {
        try {
            const value = localStorage.getItem(name);
            const number = (Number(String(value)) || 0) + 1;
            localStorage.setItem(name, String(number));
            return number;
        }
        catch (e) {
            localStorage.setItem(name, "1")
            return 1;
        }
    } catch (e) {}

    return 0;
}
